/********************************************************************************
 * vuexモジュール
 *
 * @since 2022/12/16
 * @author 松本裕介
 *
 *********************************************************************************/

//#region モジュールのインポート

  // フレームワークのインポート
  import { InjectionKey } from 'vue'
  import { createStore, useStore as baseUseStore, Store } from 'vuex'
  import createPersistedState from 'vuex-persistedstate'

  // モデルのインポート
  import { iAuth, iCrumb, iBreadcrumbs } from '@/models/Auth'; // 認証モデル

//#endregion

//#region インターフェース定義

  // ストアの型を定義する
  export interface State {
    auth: iAuth
    breadcrumbs: iBreadcrumbs
    mode: number
  }

//#endregion

//#region InjectionKeyを定義する

  // InjectionKeyを定義する
  export const key: InjectionKey<Store<State>> = Symbol()
 
//#endregion

//#region Storeを作成する

  // Storeを作成する
  export const store = createStore<State>({
    state: {
      auth: {
        OrganizationId: "",
        OrganizationCode: "",
        OrganizationName: "",
        UserId: "",
        UserCode: "",
        UserName: "",
        Token: "",
        SignInTime: ""},
      breadcrumbs: {
        Organization: {id: "", value: ""},
        User: {id: "", value: ""},
        Contract: {id: "", value: ""},
        Billing: {id: "", value: ""},
        FiscalYear: 0,
        FiscalMonth: 0,
        CloseDayClass: 0,
        IsUnpaid: false
      },
      mode: 0},
    getters: {
    },
    mutations: {
      // 認証情報更新
      updateAuth (state, auth: iAuth) {
        state.auth = auth;
      },
      // 団体更新
      updateOrganization(state, Organization: iCrumb){
        state.breadcrumbs.Organization = Organization;
      },
      // ユーザー更新
      updateUser(state, User: iCrumb){
        state.breadcrumbs.User = User;
      },
      // 契約更新
      updateContract(state, Contract: iCrumb){
        state.breadcrumbs.Contract = Contract;
      },
      // 請求更新
      updateBilling(state, Billing: iCrumb){
        state.breadcrumbs.Billing = Billing;
      },
      // 会計年更新
      updateFiscalYear(state, FiscalYear: number){
        state.breadcrumbs.FiscalYear = FiscalYear; 
      },
      // 会計月更新
      updateFiscalMonth(state, FiscalMonth: number){
        state.breadcrumbs.FiscalMonth = FiscalMonth; 
      },
      // 締め日更新
      updateCloseDayClass(state, CloseDayClass: number){
        state.breadcrumbs.CloseDayClass = CloseDayClass; 
      },
      // 未入金フラグ更新
      updateIsUnpaid(state, IsUnpaid: boolean){
        state.breadcrumbs.IsUnpaid = IsUnpaid; 
      },
      // モード
      updateMode(state, mode: number){
        state.mode = mode;
      },
    },
    actions: {
      // 認証情報設定
      setAuth({ commit }, auth: iAuth) {
        commit('updateAuth', auth);
      },
      // 団体設定
      setOrganization({ commit }, Organization: iCrumb){
        commit('updateOrganization', Organization);
      },
      // ユーザー設定
      setUser({ commit }, User: iCrumb){
        commit('updateUser', User);
      },
      // 契約設定
      setContract({ commit }, Contract: iCrumb){
        commit('updateContract', Contract);
      },
      // 請求設定
      setBilling({ commit }, Billing: iCrumb){
        commit('updateBilling', Billing);
      },
      // 会計年設定
      setFiscalYear({ commit }, FiscalYear: number){
        commit('updateFiscalYear', FiscalYear);
      },
      // 会計月設定
      setFiscalMonth({ commit }, FiscalMonth: number){
        commit('updateFiscalMonth', FiscalMonth);
      },
      // 締め日設定
      setCloseDayClass({ commit }, CloseDayClass: number){
        commit('updateCloseDayClass', CloseDayClass);
      },
      // 未入金フラグ設定
      setIsUnpaid({ commit }, IsUnpaid: boolean){
        commit('updateIsUnpaid', IsUnpaid);
      },
      // モード
      setMode({ commit }, mode: number){
        commit('updateMode', mode);
      },
    },
    plugins: [createPersistedState({storage: window.sessionStorage})]
  })

  // 独自のuserStoreメソッドを定義する
  export function useStore () {

    // InjectionKeyをuserStoreメソッドに渡す
    return baseUseStore(key);
    
  }

//#endregion