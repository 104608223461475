/********************************************************************************
 * ルーター
 *
 * @since 2022/12/16
 * @author 松本裕介
 *
 *********************************************************************************/

//#region モジュールのインポート

// フレームワークのインポート
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// ※ルーターでは、useStore()ではなく、直接storeをインポートして使用する。
import { store } from "@/store" 

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: false },
    component: () => import('../views/SignInView.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    meta: { requiresAuth: false },
    component: () => import('../views/SignInView.vue')
  },
  {
    path: '/organization',
    name: 'organization',
    meta: { requiresAuth: true },
    component: () => import('../views/OrganizationView.vue')
  },
  {
    path: '/organizationedit',
    name: 'organizationedit',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/OrganizationEditView.vue')
  },
  {
    path: '/contract',
    name: 'contract',
    meta: { requiresAuth: true },
    component: () => import('../views/ContractView.vue')
  },
  {
    path: '/contractedit',
    name: 'contractedit',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/ContractEditView.vue')
  },
  {
    path: '/user',
    name: 'user',
    meta: { requiresAuth: true },
    component: () => import('../views/UserView.vue')
  },
  {
    path: '/useredit',
    name: 'useredit',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/UserEditView.vue')
  },
  {
    path: '/aggregate',
    name: 'aggregate',
    meta: { requiresAuth: true },
    component: () => import('../views/AggregateView.vue')
  },
  {
    path: '/reconcile',
    name: 'reconcile',
    meta: { requiresAuth: true },
    component: () => import('../views/ReconcileView.vue')
  },
  {
    path: '/reconcileedit',
    name: 'reconcileedit',
    meta: { requiresAuth: true },
    props: true,
    component: () => import('../views/ReconcileEditView.vue')
  },
  {
    path: '/company',
    name: 'company',
    meta: { requiresAuth: true },
    component: () => import('../views/CompanyEditView.vue')
  },
]

// ルーターインスタンスの作成
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// グローバルビフォーガードの登録
router.beforeEach((to, from, next) => {

  // 認証が必要なページで且つトークンがない場合
  if (to.matched.some((record) => record.meta.requiresAuth) && store.state.auth.Token === "") {

    // サインインページへ遷移
    next({ path: '/signin', query: { redirect: to.fullPath } });

  // 通常の場合
  }else{

    // 次ページへ遷移
    next();

  } 
  
});

//#endregion

export default router
